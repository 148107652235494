import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../../env";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TopNavigation from "../../components/TopNavigation";
import * as moment from "moment";

export default function CreateAccount() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [codeAvocat, setCodeAvocat] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone]=useState()
  const [name, setName]=useState()
  const [password, setPassword]=useState()
  const [response, setResponse]=useState()
  const [error, setError]=useState()

  function CreateAccount(data) {
    setResponse("")
    setError("")
    axios.put(endpoint + "/user/lawyer/"+codeAvocat, {phone: phone, password: password, name:name},{headers: { "x-token": mystate.user.token }})
      .then(function (response) {
        setResponse(response.data.payload.lawyer)
      })
      .catch((error) => {
        if(error.response.status===309){
          console.log("conflict")
          setError(309)
      }
        return console.log("error");
      });
  }

  function HandleClear(){
    setResponse("")
    setError("")
    setCodeAvocat("")
    setPassword("")
    setPhone("")
  }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (data) {
      console.log(data.user)
      dispatch({ type: "setUser", payload: data.user });
    }

    if (!data) {
      return navigate("/login");
    }

    window.scrollTo(0, 0);
  }, [mystate.lawyer._id]);

  return (
    <div className="container mx-auto max-w-[800px] px-4 mt-8 noselect font-cairo mb-12">
      <TopNavigation user={mystate.lawyer} />
      <div className="border-b-2 border-gray-200 mt-8 w-full" />
      <div className="text-lg my-4">إنشاء حساب المحامي</div>
      {response &&
      <div className="my-2 flex ">
        <div className="text-lg py-2 px-2 bg-green-600 text-white ">تم انشاء حساب</div>
        <div className="text-lg py-2 px-2">{response.name}</div>
       </div>
      }
      {error===309 &&
      <div className="my-2 flex ">
        <div className="text-lg py-2 px-2 bg-red-600 text-white ">رقم الهاتف مستعمل</div>
       </div>
      }
      <div className="flex flex-col space-y-4 mt-6">
      <div className="">
            <div className="text-lg font-cairo mb-2">الرقم المهني</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 "
              onChange={(e) => setCodeAvocat(e.target.value)}
              value={codeAvocat}
            />
          </div>
          <div className="">
            <div className="text-lg font-cairo mb-2">الاسم</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 "
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="">
            <div className="text-lg font-cairo mb-2">رقم الهاتف</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-40"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </div>
          <div className="">
            <div className="text-lg font-cairo mb-2">رمز المرور</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-40"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
         

          <div className="pt-8 flex lg:space-x-8 space-x-2 justify-end flex-row-reverse">
            <div
            onClick={()=>HandleClear()}
            className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer ">
              إلغاء
            </div>
            <div
              className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
              onClick={() => CreateAccount()}
            >
              إنشاء
            </div>
          </div>
        </div>
      
    </div>
  );
}
