import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TopNavigation from "../components/TopNavigation";
import * as moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { endpoint } from "../env";

export default function DashboardMonth() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading]=useState(false)
  const [response, setResponse]= useState(false)
  const [responseYear, setResponseYear]= useState(false)
  const [selectedMonth, setSelectedMonth] = useState({key:0, value:"إختيار الشهر"});
  const [selectedYear, setSelectedYear] = useState({key:"3", value:2024});
  const yearList=[{key:"1", value:2022},{key:"2", value:2023}, {key:"3", value:2024}, {key:"4", value:2025}]

  const monthList = [
    {key:"13", value:"يناير"},
    {key:"2", value:"فبراير"},
    {key:"3", value:"مارس"},
    {key:"4", value:"أبريل"},
    {key:"5", value:"مايو"},
    {key:"6", value:"يونيو"},
    {key:"7", value:"يوليوز"},
    {key:"8", value:"غشت"},
    {key:"9", value:"شتنبر"},
    {key:"10", value:"اكتوبر"},
    {key:"11", value:"نوفمبر"},
    {key:"12", value:"ديسمبر"},
    {key:"0", value:"سنة كاملة"}
  ]
  const [is_listopen, setIs_listopen] = useState(false);
  const [is_listYearOpen, setIis_listYearOpen] = useState(false);
  
  const [insightsMonth, setInsightsMonth] = useState({
    timbre_50: 0,
    timbre_500: 0,
    totale: 0,
  });

  const [insightsYear, setInsightsYear] = useState({
    timbre_50: 0,
    timbre_500: 0,
    totale: 0,
  });

  const [insights, setInsights] = useState({
    timbre_50: 0,
    timbre_500: 0,
    totale: 0,
  });

  function calcNumbers(dt) {
    let timbre_50 = 0;
    let timbre_500 = 0;
    let totale = 0;
    dt.forEach((element) => {
      totale = totale + element.amount;
      if (element.item.price == 500) timbre_500 = timbre_500 + element.amount;
      else if (element.item.price == 50) timbre_50 = timbre_50 + element.amount;
    });
    setInsights({
      timbre_50: timbre_50,
      timbre_500: timbre_500,
      totale: totale,
    });
  }
  
  function GetSalesMonth(year, month){
    setLoading(true)
    axios.get(endpoint + "/insight/sales_total/year/"+year+"/month/"+month, {headers: { "x-token": data.user.token },})
        .then(function (response) {setInsightsMonth(response.data.payload)})
        .catch((error) => {
          setResponse(true)
          setLoading(false)
          return console.log("error");
        });

     axios.get(endpoint + "/insight/sales_operation/year/"+year+"/month/"+month, {headers: { "x-token": data.user.token },})
        .then(function (response) {
            dispatch({ type: "setSale", payload: response.data.payload.sale });
            setResponse(true)
            setLoading(false)
        })
        .catch((error) => {
          setResponse(true)
          setLoading(false)
          return console.log("error");
        });
  }
  
  function GetSalesYear(year){
    setLoading(true)
    axios.get(endpoint + "/insight/sales_total/year/"+year.value, {headers: { "x-token": data.user.token },})
        .then(function (response) {
          setInsightsMonth({
            timbre_50: 0,
            timbre_500: 0,
            totale: 0,
          })
          setInsightsYear(response.data.payload)
          setResponseYear(true)
          setLoading(false)
        })
        .catch((error) => {
          setResponseYear(true)
          setLoading(false)
          return console.log("error");
        });
  }
  function DateList() {
    return monthList.map((element) => (
      <div key={element.key}
        className={
          element.value === selectedMonth.value
            ? "mb-1 cursor-pointer bg-indigo-300 hover:bg-blue-500 hover:text-white rounded-sm px-2"
            : "mb-1 cursor-pointer  hover:bg-blue-500 hover:text-white rounded-sm px-2"
        }
        onClick={() => {
          setSelectedMonth(element);
          GetSalesMonth(selectedYear,element.key);
          setIs_listopen(!is_listopen);
          
        }}
      >
        {element.value}
      </div>
    ));
  }

  function Month(props) {
   if(loading)
    return (
    <div key={props.month.key}
        className={
          props.month.value === selectedMonth.value
            ? " w-[90px] mb-1 cursor-wait  hover:bg-black bg-black text-white hover:text-white border-2 border-black rounded-sm py-2 px-4 font-semibold mx-2"
            : " w-[90px] mb-1 cursor-not-allowed  hover:bg-gray-400 hover:text-white rounded-sm py-2 px-4 border-2 border-blue-300 mx-2"
        }
    >
    {props.month.value}
    </div>
  )
  else if (!loading)
    return (
      <div key={props.month.key}
          className={
            props.month.value === selectedMonth.value
              ? "w-[90px] mb-1 cursor-pointer  hover:bg-black bg-black text-white hover:text-white border-2 border-black rounded-sm py-2 px-4 font-semibold mx-2"
              : "w-[90px] mb-1 cursor-pointer  hover:bg-gray-400 hover:text-white rounded-sm py-2 px-4 border-2 border-blue-300 mx-2"
          }
          onClick={() => {
            setSelectedMonth(props.month);
            setIs_listopen(!is_listopen);
            GetSalesMonth(selectedYear.value,props.month.key)
          }}
      >
      {props.month.value}
      </div>
    )
  }
  function YearFull(props) {
    if(loading)
     return (
     <div key={props.month.key}
         className={
           props.month.value === selectedMonth.value
             ? " w-[140px] mb-1 cursor-wait  hover:bg-black bg-black text-white hover:text-white border-2 border-black rounded-sm py-2 px-4 font-semibold mx-2"
             : " w-[140px] mb-1 cursor-not-allowed  hover:bg-gray-400 hover:text-white rounded-sm py-2 px-4 border-2 border-blue-300 mx-2"
         }
     >
     {props.month.value}
     </div>
   )
   else if (!loading)
     return (
       <div key={props.month.key}
           className={
             props.month.value === selectedMonth.value
               ? "w-[140px] mb-1 cursor-pointer  hover:bg-black bg-black text-white hover:text-white border-2 border-black rounded-sm py-2 px-4 font-semibold mx-2"
               : "w-[140px] mb-1 cursor-pointer  hover:bg-gray-400 hover:text-white rounded-sm py-2 px-4 border-2 border-blue-300 mx-2"
           }
           onClick={() => {
            setSelectedMonth(props.month)
            setIs_listopen(!is_listopen)
            GetSalesYear(selectedYear)
           }}
       >
       {props.month.value}
       </div>
     )
   }
  function Year(props) {
    if(loading)
     return (
     <div key={props.year.key}
         className={
           props.year.value === selectedYear.value
             ? "mb-1 cursor-wait  hover:bg-black bg-black text-white hover:text-white border-2 border-black rounded-sm py-2 px-4 font-semibold mx-2"
             : "mb-1 cursor-not-allowed  hover:bg-gray-400 hover:text-white rounded-sm py-2 px-4 border-2 border-blue-300 mx-2"
         }
     >
     {props.year.value}
     </div>
   )
   else if (!loading)
     return (
       <div key={props.year.key}
           className={
             props.year.value === selectedYear.value
               ? "mb-1 cursor-pointer  hover:bg-black bg-black text-white hover:text-white border-2 border-black rounded-sm py-2 px-4 font-semibold mx-2"
               : "mb-1 cursor-pointer  hover:bg-gray-400 hover:text-white rounded-sm py-2 px-4 border-2 border-blue-300 mx-2"
           }
           onClick={() => {
             setSelectedYear(props.year);
             setSelectedMonth({key:0, value:"إختيار الشهر"})
             setResponse(false)
             setIis_listYearOpen(!is_listYearOpen);
           }}
       >
       {props.year.value}
       </div>
     )
   }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (!data) {
      return navigate("/login");
    }
    window.scrollTo(0, 0);
  }, );

  return (
    <div className="container mx-auto mt-8 noselect font-cairo">
      <TopNavigation user={data.user} />

      <div className=" text-2xl mb-12 font-semibold mt-16">
        مجموع مداخيل الدمغة
      </div>
      <div className="flex items-center mb-12">
            <div className="text-lg font-cairo  mx-4">إختيار السنة</div>
            <div className="flex items-center">
              <Year year={yearList[0]}/>
              <Year year={yearList[1]}/>
              <Year year={yearList[2]}/>
              <Year year={yearList[3]}/>
            </div>
      </div>
      <div className="flex items-center mb-12">
            <div className="text-lg font-cairo  mx-4">إختيار الأشهر</div>
            <div className="">
              <div className="flex items-center">
                <Month month={monthList[0]}/>
                <Month month={monthList[1]}/>
                <Month month={monthList[2]}/>
                <Month month={monthList[3]}/>
                <Month month={monthList[4]}/>
                <Month month={monthList[5]}/>
              </div>
              <div className="flex items-center">
                <Month month={monthList[6]}/>
                <Month month={monthList[7]}/>
                <Month month={monthList[8]}/>
                <Month month={monthList[9]}/>
                <Month month={monthList[10]}/>
                <Month month={monthList[11]}/>
              </div>
              <YearFull month={monthList[12]}/>
            </div>
      </div>

      {(loading) &&
        <div className="text-center text-xl font-semibold">
          جاري عملية البحث...
        </div>
      }
      {(!loading) && <div>
        {selectedMonth.key==="0" &&
          <div className="bg-cyan-900 lg:w-[620px] p-4  text-white mb-2 font-semibold space-y-2">
            <div className="flex justify-between text-xl">
              <div className="">المجموع الاجمالي السنوي</div>
              <div>{insightsYear.total_amount} درهم</div>
            </div>
            <div className="flex justify-between">
              <div className="">فئة الدمغة (50) :</div>
              <div className="">وحدة : {insightsYear.stamp_50? insightsYear.stamp_50.amount/50 : 0}</div>
              <div>مبلغ : {insightsYear.stamp_50?insightsYear.stamp_50.amount:0} درهم</div>
            </div>
            <div className="flex justify-between">
              <div className="">فئة الدمغة (500)</div>
              <div className="">وحدة : {insightsYear.stamp_500? insightsYear.stamp_500.amount/500 : 0}</div>
              <div>مبلغ : {insightsYear.stamp_500?insightsYear.stamp_500.amount:0} درهم</div>
            </div>
        </div>
        }
        {response && selectedMonth.key!=="0" &&
        <div>
          <div className="bg-black lg:w-[620px] p-4  text-white mb-12 font-semibold space-y-2">
          <div className="flex justify-between text-xl">
            <div className="">المجموع الاجمالي الشهري</div>
            <div>{insightsMonth.total_amount} درهم</div>
          </div>
          <div className="flex justify-between">
            <div className="">فئة الدمغة (50) :</div>
            <div className="">وحدة : {insightsMonth.stamp_50? insightsMonth.stamp_50.amount/50 : 0}</div>
            <div>مبلغ : {insightsMonth.stamp_50?insightsMonth.stamp_50.amount:0} درهم</div>
          </div>
          <div className="flex justify-between">
            <div className="">فئة الدمغة (500)</div>
            <div className="">وحدة : {insightsMonth.stamp_500? insightsMonth.stamp_500.amount/500 : 0}</div>
            <div>مبلغ : {insightsMonth.stamp_500?insightsMonth.stamp_500.amount:0} درهم</div>
          </div>
        </div>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">
                <div className="font-bold font-cairo">المكتب</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">فئة الدمغة</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">وحدات</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">
                  الرقم التسلسي للدمغة
                </div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">طريقة الاداء</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">تاريخ العملية</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">مد ينة</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">نقطة البيع</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">المبلغ الإجمالي</div>
              </TableCell>
            </TableRow>
          </TableHead>
          {mystate.sale.length && (
            <TableBody>
              {mystate.sale.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="right">
                    <div className="font-cairo">{row.buyer.name}</div>
                  </TableCell>
                  <TableCell align="right">
                        <div
                          className={
                            row.item.price == "500"
                              ? "font-cairo font-semibold text-orange-600"
                              : "font-cairo font-semibold text-blue-600"
                          }
                        >
                          ({row.item.price})
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">{row.quantity}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div
                          className={
                            row.item.price == "500"
                              ? "font-cairo font-semibold text-orange-600"
                              : "font-cairo font-semibold text-blue-600"
                          }
                        >
                          {row.stamp_serie.from} - {row.stamp_serie.to}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.payment.p_type === "check" ? "شيك" : "نقد"}
                        </div>
                        <div className="">{row.payment.p_code}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {moment(row.created_at.toString()).format("L")}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                        {row.city}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.zone && row.zone.toUpperCase()}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo bg-mygreen py-1 px-2 font-bold text-white">
                          {row.amount} درهم
                        </div>
                      </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        </TableContainer>
        </div>
        
        }

        
      </div>}
     
    </div>
  );
}
